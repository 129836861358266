import "./Footer.scss";
import Logo from "../../Assets/Images/Footer/Logo.png";
import Contactanos from "../../Assets/Images/Footer/Contactanos.png";
import { RouterLink } from '../../Components/RouterLink/RouterLink';

export const Footer = () => {
  return (
    <div className="Footer">
      <div className="container-footer">
        <div className="section-up">
          <div className="card card-conecctive">
            <div className="card-body">
              <RouterLink to="/" classLink="cont-link-footer-img">
                <img src={Logo} alt="" />
              </RouterLink>
              <div className="navbar-footer">
                <hr />
                <div className="row">
                  <nav className="col-md-2 d-none d-md-block">
                    <div className="sidebar-sticky">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <RouterLink
                            to="/"
                            classLink="nav-link active color-blue"
                          >
                            Inicio
                          </RouterLink>
                        </li>
                        <li className="nav-item">
                          <RouterLink to="/solutions" classLink="nav-link">
                            Soluciones
                          </RouterLink>
                        </li>
                        <li className="nav-item">
                          <RouterLink to="/resources" classLink="nav-link">
                            Recursos
                          </RouterLink>
                        </li>
                        <li className="nav-item">
                          <RouterLink to="/contact" classLink="nav-link">
                            Contacto
                          </RouterLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <RouterLink to="/contact" classLink="contatanos-link-footer">
                <img className="contactanos-img" src={Contactanos} />
              </RouterLink>
            </div>
          </div>
          <div className="card" style={{ width: "22rem" }}>
            <div className="card-body">
              <h5 className="card-title">Oficinas centrales en LATAM</h5>
              <a href="tel:+197332140621390" className="card-subtitle">
                +1 973 321 4062 1390
              </a>
              <a href="#" className="card-link">
                Market Street, Suite 200, San Francisco, California, 94102,
              </a>
              <a href="mailto:sales@connectiveit.com.mx" className="card-link">
                sales@connectiveit.com.mx
              </a>
            </div>
          </div>
          <div className="card" style={{ width: "20rem" }}>
            <div className="card-body politicas">
              <h5 className="card-title">Políticas</h5>
              <a href="#" className="card-link">
                Aviso de privacidad
              </a>
              <a href="#" className="card-link">
                Términos y condiciones
              </a>
              <a href="#" className="card-link">
                Preguntas frecuentes
              </a>
            </div>
          </div>
        </div>
        <div className="section-down">
          <div className="text-address">
            <span className="text-adress-span">
              Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa,
              Mexico City.
            </span>
          </div>
          <div className="call-me">
            <a href="tel:+525589776528">Llámanos +52 55 8977 6528</a>
          </div>
          <div className="logos">
            <hr />
            <a
              href="https://www.instagram.com/iws_services_latam/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-instagram"></i>
            </a>
            <hr />
            <a
              href="https://www.facebook.com/IWSServicesMexico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <hr />
            <a
              href="https://www.linkedin.com/company/iws-services/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="bi bi-linkedin"></i>
            </a>
            <hr />
            <a
              href="https://twitter.com/iws_services"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="bi bi-twitter"></i>
            </a>
            <hr />
            <a
              href="https://www.youtube.com/channel/UC5N-VKX6WnQBxwn6Awfq_-A/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i className="bi bi-youtube"></i>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;