import React from 'react';
import './NotFound.scss'

const NotFound = () => {
    return (
        <div>
            Erro 404
        </div>
    );
};

export { NotFound };