import React from 'react';
import './Dynamics.scss'

import { Button } from '../../Components/Button/Button';
import { Title } from '../../Components/Title/Title';
import { Subtitle } from '../../Components/Subtitle/Subtitle';
import { RouterLink } from '../../Components/RouterLink/RouterLink';
import { CarouselBoostrap } from '../../Components/CarouselBoostrap/CarouselBoostrap';
import { ContentItemCaroucel } from './ContentItemCaroucel';

import Dynamicsintro from '../../Assets/Images/Dynamics/Intro.png';
import ImagenPlus from '../../Assets/Images/Dynamics/ImagenPlus.png';
import analisis from '../../Assets/Images/Dynamics/analisis.png';
import microsoft from '../../Assets/Images/Dynamics/Microsoft.png';
import RuletaCompleta from '../../Assets/Images/Dynamics/RuletaCompleta.png';
import Video from '../../Assets/Images/Dynamics/Video.png';
import CarruselBusinessCentral from '../../Assets/Images/Dynamics/CarruselBusinessCentral.png';
import RectánguloSeccion from '../../Assets/Images/Dynamics/RectánguloSeccion.png';
import CustomerInsights from '../../Assets/Images/Dynamics/CustomerInsights.png';
import SeccionSales from '../../Assets/Images/Dynamics/SeccionSales.png';
import BusinessEnvolvente from '../../Assets/Images/Dynamics/BusinessEnvolvente.png';
import SalesEnvolvente from '../../Assets/Images/Dynamics/SalesEnvolvente.png';
import { useRef } from 'react';

const Dynamics = () => {

    const myRef = useRef(null);
  
    const scrollToElement = () => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    return (
        <div className='Dynamics'>
            <div className='dynamics-intro row ps-5'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-intro-info'>
                    <div className='row'>
                        <div className='col-12 dynamics-intro--title'><Title>Dynamics 365</Title></div>
                        <div className='col-12 mt-5 dynamics-intro__p'>
                            <p className='dynamics-intro__p--description'>
                                Descubre el mundo de posibilidades que Dynamics 365, la innovadora línea de productos de software de negocios desarrollada por Microsoft, ofrece para impulsar el crecimiento y la eficiencia de tu empresa.
                            </p>
                        </div>
                        <div className='mt-5 dynamics-intro__button'>
                            <Button  onClick={scrollToElement}>IMPULSA TU NEGOCIO</Button>
                        </div>
                    </div>
                </div>
                <div className=' col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-intro__content-img'>
                    <img src={Dynamicsintro} alt="" className='dynamics-intro--img' />
                </div>
            </div>

            <div className='dynamics-plus row'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-plus-img'>
                    <img src={ImagenPlus} alt="" className='dynamics-plus--img' />
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-plus-info'>
                    <div className='dynamics-plus__info' ref={myRef}>
                        <div className='col-12 dynamics-plus--title'>
                            <Title>Un <span className='color-b1'>plus</span> para tu empresa</Title>
                        </div>
                        <div className='col-12 mt-5 dynamics-plus__p'>
                            <p className='dynamics-plus__p--description'>
                                Nuestra plataforma integral, basada en la nube, integra diversas aplicaciones y servicios diseñados para gestionar y optimizar funciones clave en áreas como ventas, servicio al cliente, operaciones, finanzas, recursos humanos y más.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='dynamics-plusTwo row'>
                <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl- col-xxl-4 dynamics-plusTwo__info'>
                    <div className='dynamics-plustwo-info'>
                        <Subtitle>Flexibilidad y Colaboración:</Subtitle>
                        <p className='dynamics-plustwo--text'>
                            Dynamics 365 utiliza IA y analítica avanzada para proporcionar información valiosa y apoyar la toma de decisiones informadas.
                        </p>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl- col-xxl-4 dynamics-plusTwo-img'>
                    <img src={analisis} alt="" className='dynamics-plusTwo--img w-100' />
                </div>
                <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl- col-xxl-4 dynamics-plusTwo__info'>
                    <div className='dynamics-plustwo-info'>
                        <Subtitle>Inteligencia Artificial y Analítica</Subtitle>
                        <p className='dynamics-plustwo--text'>
                            Dynamics 365 utiliza IA y analítica avanzada para proporcionar información valiosa y apoyar la toma de decisiones informadas.
                        </p>
                    </div>
                </div>
            </div>

            <div className='dynamics-tools row'>
                <div className='dynamics-tools-title text-center'>
                    <Title><span className='color-b1'>Herramientas</span> de Dynamics</Title>
                </div>
                <CarouselBoostrap indicators={3}>
                    <div className="carousel-item active">
                        <ContentItemCaroucel img={CarruselBusinessCentral} title="Business Central" showEnvolvent={true} imgEnvolvent={BusinessEnvolvente} RectánguloSeccion={RectánguloSeccion}>
                            Dynamics 365 Business Central es una solución integral de gestión empresarial desarrollada por Microsoft. Está diseñada para ayudar a las pequeñas y medianas empresas (PYMEs) a optimizar sus operaciones, mejorar las interacciones con los clientes y tomar decisiones más informadas.
                        </ContentItemCaroucel>
                        <ContentBenefits>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pe-5'>
                                    <Benefits title="1. Integración Total: ">
                                        Conecta todos los aspectos de tu empresa, desde finanzas y ventas hasta operaciones y servicio al cliente. La integración fluida facilita la toma de decisiones informadas.
                                    </Benefits>
                                    <Benefits title="2. Automatización Eficiente:">
                                        Optimiza tus procesos comerciales con automatización inteligente. Desde la gestión de inventario hasta la generación de informes financieros, ahorra tiempo y reduce errores.
                                    </Benefits>
                                    <Benefits title="3. Acceso en Tiempo Real: ">
                                        Accede a datos críticos en tiempo real desde cualquier lugar. Ya sea en la oficina, en casa o en movimiento, mantén el control total de tu negocio con la aplicación móvil de Dynamics Business Central.
                                    </Benefits>
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-5'>
                                    <Benefits title="4. Escalabilidad: ">
                                        Dynamics Business Central se adapta a las cambiantes necesidades de tu empresa, desde pequeñas operaciones hasta corporativos.
                                    </Benefits>
                                    <Benefits title="5. Seguridad Avanzada:">
                                        Protege tu información empresarial con características de seguridad avanzadas. Control de accesos, encriptación y auditorías integradas garantizan la confidencialidad y la integridad de tus datos.
                                    </Benefits>
                                    <Benefits title="6. Soporte Continuo:">
                                        Obtén soporte continuo y actualizaciones que reflejen las últimas tendencias empresariales y tecnológicas.
                                    </Benefits>
                                </div>
                            </div>
                        </ContentBenefits>
                    </div>
                    <div className="carousel-item">
                        <ContentItemCaroucel img={CustomerInsights} title="Customer insights" class_bg='bg-color-caroucel_2' RectánguloSeccion={RectánguloSeccion}>
                            Es una herramienta potente para las empresas que buscan aprovechar todo el potencial de sus datos de clientes, impulsar experiencias personalizadas para los clientes y tomar decisiones informadas para mejorar la satisfacción y la fidelidad del cliente en general.
                        </ContentItemCaroucel>
                        <ContentBenefits>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pe-5'>
                                    <Benefits title="1. Vista Integral del cliente:">
                                        Agrega datos de clientes desde diversas fuentes, como ventas, marketing, servicio y otras interacciones con el cliente, para crear una vista unificada y holística de cada cliente.
                                    </Benefits>
                                    <Benefits title="2. Integración de Datos:">
                                        Se integra de manera fluida con otros productos de Microsoft, así como con aplicaciones de terceros, para recopilar datos de varios puntos de contacto, incluidos sistemas CRM, bases de datos transaccionales e interacciones en línea
                                    </Benefits>
                                    <Benefits title="3. Aprendizaje Automático e Inteligencia Artificial:">
                                        Utiliza análisis avanzados, aprendizaje automático e inteligencia artificial para identificar patrones, tendencias y comportamientos del cliente. Esto permite hacer predicciones basadas en datos.
                                    </Benefits>
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-5'>
                                    <Benefits title="4. Segmentación: ">
                                        Los usuarios pueden crear segmentos de clientes en función de atributos específicos, comportamientos o datos demográficos. Esto ayuda a las empresas a adaptar campañas de marketing, ofertas y estrategias de comunicación para segmentos específicos de audiencia.
                                    </Benefits>
                                    <Benefits title="5. Información en tiempo real: ">
                                        Proporciona análisis en tiempo real, permitiendo a las empresas mantenerse actualizadas con las preferencias y comportamientos de los clientes.
                                    </Benefits>
                                    <Benefits title="6. Mapeo del recorrido: ">
                                        La plataforma permite la visualización de los recorridos de los clientes, ayudando a las organizaciones a comprender cómo interactúan los clientes con su marca en diferentes puntos de contacto.
                                    </Benefits>
                                </div>
                            </div>
                        </ContentBenefits>
                    </div>
                    <div className="carousel-item">
                        <ContentItemCaroucel img={SeccionSales} title="Dynamics 365 Sales" showEnvolvent={true} imgEnvolvent={SalesEnvolvente} RectánguloSeccion={RectánguloSeccion}>
                            Está diseñada para ayudar a las empresas a gestionar eficazmente sus procesos de ventas, desde la captación de clientes potenciales hasta el cierre de tratos y el seguimiento del servicio postventa.
                        </ContentItemCaroucel>
                        <ContentBenefits>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pe-5'>
                                    <Benefits title="1. Gestión de clientes potenciales (Leads): ">
                                        Permite a las empresas realizar un seguimiento y gestionar clientes potenciales desde su origen hasta la conversión en oportunidades de venta.
                                    </Benefits>
                                    <Benefits title="2. Gestión de oportunidades de venta:">
                                        Facilita el seguimiento de oportunidades de venta, incluyendo detalles como la probabilidad de cierre, el valor de la oportunidad y los pasos a seguir para cerrar la venta.
                                    </Benefits>
                                    <Benefits title="3. Integración con Office 365: ">
                                        Dynamics 365 Sales se integra estrechamente con las aplicaciones de Office 365, como Outlook y Excel, para mejorar la productividad y la colaboración entre los equipos de ventas.
                                    </Benefits>
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-5'>
                                    <Benefits title="4. Automatización de ventas:">
                                        Ofrece herramientas para automatizar tareas repetitivas, como el seguimiento de correos electrónicos, la creación de informes y la asignación de tareas, lo que permite a los equipos de ventas centrarse en actividades más estratégicas.
                                    </Benefits>
                                    <Benefits title="5. Seguimiento de actividades: ">
                                        Permite realizar un seguimiento detallado de las interacciones con los clientes, incluyendo correos electrónicos, llamadas, reuniones y otras actividades relevantes
                                    </Benefits>
                                    <Benefits title="6.Análisis y reporting:">
                                        Proporciona herramientas de análisis y generación de informes para evaluar el rendimiento de las ventas, identificar tendencias y tomar decisiones informadas.
                                    </Benefits>
                                </div>
                            </div>
                        </ContentBenefits>
                    </div>
                </CarouselBoostrap>
            </div>

            <div className='dynamics-tools_more row'>
                <div className='dynamics-tools_more-title text-center'>
                    <Title classTitle='d-pc'>Más herramientas de Dynamics</Title>
                    <Title classTitle='d-movile'>Más <span className='color-b1'>herramientas</span>  de Dynamics</Title>
                </div>
                <div className='dynamics-tools_more-info'>
                    <div className='row dynamics-tools_more-row'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-tools_more-img-content'>
                            <img src={RuletaCompleta} alt="" className='dynamics-tools_more--img' />
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dynamics-tools_more-col-text'>
                            <div className='row'>
                                <div className='col-12 dynamics-tools_more--title'><Title>Customer insights</Title></div>
                                <div className='col-12 mt-5 dynamics-tools_more__p'>
                                    <p className='dynamics-tools_more__p--description'>
                                        Transforme los datos de sus clientes en información procesable en tiempo real y conozca a sus clientes con una plataforma de datos de clientes (CDP) impulsada por IA.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='dynamics-banner row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div className='dynamics-banner__info'>
                        <img src={microsoft} alt="" srcset="" className='w-100' />
                        <div className='dynamics-banner__info-text'>
                            <div className='dynamics-banner-content-info'>
                                <p>
                                    <span className='dynamics-banner__info--text'>Conoce más beneficios sobre Dynamics 365</span>
                                </p>

                                <div>
                                <RouterLink to='/contact'>
                                    <Button>CONÓCENOS</Button>
                                 </RouterLink>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='dynamics-video row' hidden>
                <div className='dynamics-video-title text-center'>
                    <Title>Descubre las posibilidades</Title>
                </div>
                <div className='dynamics-video-content'>
                    <div className='dynamics-video__content-video'>
                        <div className='dynamics-video--video'>
                            {/* <img src={Video} alt="" className='dynamics-video--img w-100' /> */}
                            {/* <video className='videoTag' autoPlay loop muted>
                                <source src='https://www.youtube.com/watch?v=D0UnqGm_miA&ab_channel=SolutionsForAll' type='video/mp4' />
                            </video> */}
                        </div>
                    </div>
                    <div className='dynamics-video-content-info'>
                        <div className='dynamics-video-content__text'>
                            <p className='dynamics-video-content--p'>¡Impulsa tu negocio con Microsoft Dynamics 365!</p>
                        </div>
                        <Button>CONÓCENOS</Button>
                    </div>
                </div>
            </div>

            <div className='dynamics-offer row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 dynamics-offer__info'>
                    <div className='dynamics-offer__info-text'>
                        <div className='dynamics-offer__title-text'><span className='dynamics-offer--title'>¿Qué ofrecemos?</span></div>
                        <div className='dynamics-offer-description'><span className='dynamics-offer--text'>Delegar todos los servicioadministrativos para una mejor productividad en base a la atención al cliente.</span></div>
                        <div className='dynamics-offer-button'>
                            <RouterLink to='/'>
                                <Button>IMPULSA TU NEGOCIO</Button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 cont-btns'>
                    <Button classButton='btn-dynamics-offer line-h'>
                        <p>
                            <div className='dynamics-offer__btn-number btn-hours-color'><span>24 hrs</span></div>
                            <div className='dynamics-offer__btn-text btn-hours-color'><span>de servicio</span></div>
                        </p>
                    </Button>

                    <Button classButton='btn-dynamics-offer--ticket line-h'>
                        <p>
                            <div className='dynamics-offer__btn-number btn-ticket-color'><span>10,000</span></div>
                            <div className='dynamics-offer__btn-text btn-ticket-color'><span>Tickets resueltos</span></div>
                        </p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export { Dynamics };


const ContentBenefits = ({ children }) => {
    return (
        <div className='content-benefits'>
            <div className='content-benefits-home'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <p className='content-benefits-title'>
                            Beneficios:
                        </p>
                    </div>
                </div>
                <div className='content-benefits-body'>
                    <div className='content-benefits-list'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}



const Benefits = ({ title, children }) => {
    return (
        <div className='Benefits'>
            <div className='Benefits-title'>
                <p className='Benefits--title'>
                    {title}
                </p>
            </div>
            <div className='Benefits-text'>
                <p className='Benefits--text'>
                    {children}
                </p>
            </div>
        </div>
    )
}