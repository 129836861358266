import React from 'react';
import './Title.scss'

interface Props {
    children: React.ReactNode
    texto?: string
    classTitle?: string
}

const Title: React.FC<Props> = ({ children, classTitle = 'title__color--secundary' }) => {
    return (
        <div className={`Title ${classTitle}`}>
            {children}
        </div>
    );
};

export { Title };