import React from 'react';
import './Subtitle.scss'

interface Props {
    children: React.ReactNode
    classSubTitle?: string
}

const Subtitle: React.FC<Props> = ({ children, classSubTitle = 'subtitle__color--secundary' }) => {
    return (
        <div className={`Subtitle ${classSubTitle}`}>
            {children}
        </div>
    );
};

export { Subtitle };