import React from "react";
import "./TeamViewer.scss";
import { Title } from "../../Components/Title/Title";
import { Button } from "../../Components/Button/Button";
import img_principal from "../../Assets/Images/Team View/principal.png";
import mobile_principal from "../../Assets/Images/Team View/Movil/mobile.png";
import fondo_m from "../../Assets/Images/Team View/Movil/fondo_m.png";
import base from "../../Assets/Images/Team View/Base.png";
import { RouterLink } from "../../Components/RouterLink/RouterLink";

const TeamViewer = () => {
  return (
    <div className="TeamViewer">
      <div className="row teamviewer-intro">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 teamviewer-intro-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile teamviewer-intro--title">
              <Title>
                <p>
                  <span className="color-subitlte-powerapps">
                    {" "}
                    <Title>Team View</Title>
                  </span>
                </p>
              </Title>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>Team View</Title>
            </div>
            <div className="col-12 mt-5 teamviewer-intro__p">
              <p className="teamviewer-intro__p--description">
                Procesa, analiza y comparte información en tiempo real con sus
                equipos mejorando la productividad, agilizar las operaciones y
                ofreciendo servicios nuevos.
              </p>
            </div>
            <div className="col-12 mt-5 powerapps__button">
              <RouterLink to="/contact">
                <Button>IMPULSA TU NEGOCIO</Button>
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 teamviewer__content-img">
          <img alt="" src={img_principal} className="teamviewer-intro--img" />
          <img
            src={mobile_principal}
            alt=""
            className="about-intro--img--mobile"
          />
        </div>
      </div>

      <div className="servicios_teamviewer">
        <img alt="" src={base} className="teamviewer--img" />
        <img alt="" src={fondo_m} className="teamviewer_mobile" />
      </div>

      <div className="row solutions_offer__content">
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 solutions_offer__info">
          <div className="solutions_offer">
            <div className="solutions_offer__title-text">
              <span className="solutions_offer--title">¿Qué ofrecemos?</span>
            </div>
            <div className="solutions_offer-description">
              <span className="solutions_offer--text">
                Delegar todos los servicioadministrativos para una mejor
                productividad en base a la atención al cliente.
              </span>
            </div>
            <div className="solutions_offer-button">
              <RouterLink to="/contact">
                <Button>IMPULSA TU NEGOCIO</Button>
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 cont-btns">
          <Button classButton="btn-solutions_offer line-h">
            <p>
              <div className="solutions_offer__btn-number btn-hours-color">
                <span>24 hrs</span>
              </div>
              <div className="solutions_offer__btn-text btn-hours-color">
                <span>de servicio</span>
              </div>
            </p>
          </Button>

          <Button classButton="btn-solutions_offer--ticket line-h">
            <p>
              <div className="solutions_offer__btn-number btn-ticket-color">
                <span>10,000</span>
              </div>
              <div className="solutions_offer__btn-text btn-ticket-color">
                <span>Tickets resueltos</span>
              </div>
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export { TeamViewer };
