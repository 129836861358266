import React from 'react';
import './Solutions.scss'

import { Title } from '../../Components/Title/Title';
import { FeatureItem } from '../../Components/FeatureItem/FeatureItem';


import intro from '../../Assets/Images/Solutions/intro.png'
import responsive from '../../Assets/Images/Solutions/intro_1.png'
import solutions_feature_1 from '../../Assets/Images/Solutions/intro_feature_1.png'
import solutions_feature_2 from '../../Assets/Images/Solutions/intro_feature_2.png'
import solutions_feature_3 from '../../Assets/Images/Solutions/intro_feature_3.png'
import solutions_feature_4 from '../../Assets/Images/Solutions/intro_feature_4.png'
import solutions_feature_5 from '../../Assets/Images/Solutions/intro_feature_5.png'
import solutions_feature_6 from '../../Assets/Images/Solutions/intro_feature_6.png'
import solutions_feature_7 from '../../Assets/Images/Solutions/intro_feature_7.png'
import solutions_feature_8 from '../../Assets/Images/Solutions/intro_feature_8.png'
import { Button } from '../../Components/Button/Button';
import { RouterLink } from '../../Components/RouterLink/RouterLink';
import { useRef } from 'react';


const Solutions = () => {

    const myRef = useRef(null);
  
    const scrollToElement = () => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
    
    
    return (
        <div className='Solutions'>

            <div className='row solutions-intro ps-5'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 solutions-intro-info'>
                    <div className='row'>
                        <div className='col-12 solutions-intro--title'><Title>Portales Web</Title></div>
                        <div className='solutions-intro__p'>
                            <p className='solutions-intro__p--text'>
                                SOLUCIONES ESPECIALIZADAS
                            </p>
                        </div>
                        <div className='col-12 mt-5 solutions-intro__p'>
                            <p className='solutions-intro__p--description'>
                                Connective IT se destaca por ofrecer soluciones de desarrollo web personalizadas, centradas en la calidad, la seguridad y la satisfacción del cliente, utilizando tecnologías líderes como Java, HTML y Angular.
                            </p>
                        </div>
                        <div className='mt-5 solutions-intro__button'>
                            <Button  onClick={scrollToElement}>DESCUBRE CÓMO</Button>
                        </div>
                    </div>
                </div>
                <div className=' col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 solutions-intro__content-img'>
                    <img src={intro} alt="" className='solutions-intro--img' />
                </div>
            </div>


            <div className='row solutions-feature mtop-3' ref={myRef}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center'>
                    <Title><span className='color-b1'>Características</span> de nuestro servicio</Title>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5 ps-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                            <FeatureItem title='1. Desarrollo de Portales Web Personalizados:'>
                                <li>La empresa se especializa en la creación de portales web a medida para satisfacer las necesidades específicas de cada cliente.</li>
                                <li>Utiliza tecnologías de vanguardia como Java para el desarrollo del backend, garantizando robustez, seguridad y escalabilidad.</li>
                            </FeatureItem>
                        </div>
                        <div className='Solutions-d_movile col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='solutions_feature_6_movile'>
                                <img src={solutions_feature_6} alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ptop-5'>
                            <FeatureItem title='2. Diseño Responsivo y Atractivo'>
                                <li>Los portales web desarrollados por la empresa son completamente responsivos, lo que significa que se adaptan de manera óptima a diferentes dispositivos y tamaños de pantalla.</li>
                                <li>Se presta especial atención al diseño UX/UI para garantizar una experiencia de usuario intuitiva y atractiva.</li>
                            </FeatureItem>
                        </div>
                    </div>
                </div>

                <div className='Solutions-d_pc  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <img src={responsive} alt="" className='w-100' />
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4'>
                            <div className='solutions_feature_1'>
                                <img src={solutions_feature_1} alt="" />
                            </div>
                            <div className='solutions_feature_2 Solutions-d_pc '>
                                <img src={solutions_feature_2} alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8'>
                            <div className='feature_3'>
                                <FeatureItem title='3. Integración de Tecnologías Frontend'>
                                    <li>HTML y Angular se utilizan para el desarrollo del frontend, asegurando interfaces de usuario dinámicas e interactivas. </li>
                                    <li> La empresa se compromete a seguir las mejores prácticas de desarrollo frontend para garantizar la eficiencia y la velocidad de carga de las páginas.</li>
                                </FeatureItem>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='Solutions-d_pc solutions_feature_3'>
                                <img src={solutions_feature_3} alt="" />
                            </div>
                            <div className='Solutions-d_movile'>
                                <img src={responsive} alt="" className=' w-100' />
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                            <div className=''>
                                <FeatureItem title='4. Desarrollo Ágil y Colaborativo:'>
                                    <li>Se adopta un enfoque ágil en el desarrollo de proyectos, lo que permite una colaboración estrecha con los clientes a lo largo del proceso. </li>
                                    <li>Los clientes tienen acceso regular a demos y actualizaciones, lo que facilita la retroalimentación y asegura la alineación con las expectativas.</li>
                                </FeatureItem>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='solutions_feature_4'>
                                <img src={solutions_feature_4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-2 offset-xl-2 offset-xxl-2'>
                            <div className=''>
                                <FeatureItem title='5. Mantenimiento Continuo'>
                                    <li>La empresa no solo se centra en el desarrollo inicial, sino que también ofrece servicios de mantenimiento continuo para asegurar que los portales web estén actualizados y funcionando de manera óptima.</li>
                                </FeatureItem>
                            </div>
                        </div>
                        <div className='Solutions-d_pc col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='solutions_feature_5'>
                                <img src={solutions_feature_5} alt="" className=' w-100' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <div className='row'>
                        <div className='Solutions-d_pc col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='solutions_feature_6'>
                                <img src={solutions_feature_6} alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                            <div className=''>
                                <FeatureItem title='6. Optimización para Motores de Búsqueda (SEO):'>
                                    <li>La empresa no solo se centra en el desarrollo inicial, sino que también ofrece servicios de mantenimiento continuo para asegurar que los portales web estén actualizados y funcionando de manera óptima.</li>
                                </FeatureItem>
                            </div>
                        </div>
                        <div className='Solutions-d_pc col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 solutions_feature_7'>
                            <div className=''>
                                <img src={solutions_feature_7} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mtop-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='solutions_feature_8'>
                                <img src={solutions_feature_8} alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                            <div className=''>
                                <FeatureItem title='7. Soporte Técnico:'>
                                    <li>La empresa brinda un sólido servicio de soporte técnico para abordar cualquier problema o inquietud que pueda surgir después del lanzamiento.</li>
                                </FeatureItem>
                            </div>
                        </div>
                        <div className='Solutions-d_movile col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 '>
                            <div className='solutions_feature_2_Movile'>
                                <img src={solutions_feature_2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row solutions_offer__content'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 solutions_offer__info'>
                    <div className='solutions_offer'>
                        <div className='solutions_offer__title-text'><span className='solutions_offer--title'>¿Qué ofrecemos?</span></div>
                        <div className='solutions_offer-description'><span className='solutions_offer--text'>Delegar todos los servicioadministrativos para una mejor productividad en base a la atención al cliente.</span></div>
                        <div className='solutions_offer-button'>
                            <RouterLink to='/contact'>
                                <Button>IMPULSA TU NEGOCIO</Button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 cont-btns'>
                    <Button classButton='btn-solutions_offer line-h'>
                        <p>
                            <div className='solutions_offer__btn-number btn-hours-color'><span>24 hrs</span></div>
                            <div className='solutions_offer__btn-text btn-hours-color'><span>de servicio</span></div>
                        </p>
                    </Button>

                    <Button classButton='btn-solutions_offer--ticket line-h'>
                        <p>
                            <div className='solutions_offer__btn-number btn-ticket-color'><span>10,000</span></div>
                            <div className='solutions_offer__btn-text btn-ticket-color'><span>Tickets resueltos</span></div>
                        </p>
                    </Button>
                </div>
            </div>
        </div>
    );
};
export { Solutions };