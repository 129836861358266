import React from 'react';
import './RouterLink.scss'

import { Link } from 'react-router-dom';

interface Props {
    to: string
    classLink?: string
    children: React.ReactNode
}

const RouterLink: React.FC<Props> = ({ to, children, classLink }) => {
    return (
        <Link to={to} className={classLink} >
            {children}
        </Link>
    );
};

export { RouterLink };