import React from 'react';
import './FeatureItem.scss'

interface Props {
    title: string
    children: React.ReactNode
}
const FeatureItem: React.FC<Props> = ({ title, children }) => {
    return (
        <div className='FeatureItem'>
            <div className='FeatureItem__head'>
                <span className='FeatureItem__head--title'>{title}</span>
            </div>
            <div className='FeatureItem__body'>
                <ul className='FeatureItem__body-ul'>
                    {children}
                </ul>
            </div>
        </div>
    );
};

export { FeatureItem };