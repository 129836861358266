import React from 'react'
import "../Button/Button.scss"

//Estas son las propiedades que necesita el componete 
interface Props {
  children: React.ReactNode
  classButton?: string
  onClick?: () => void
}

//componete
const Button: React.FC<Props> = ({ children, onClick, classButton = 'button__prymary' }) => {
  return (
    <button type="button" className={`btn ${classButton}`} onClick={onClick}>{children}</button>
  )
}

//exportar el componete por medio de objeto  {nombre}
export { Button };
