import React, { FC, ReactNode } from 'react';
import './Layout.scss'
import { Header } from '../Containers/Header/Header';
import { Footer } from '../Containers/Footer/Footer';

interface Props {
    children: ReactNode
}
const Layout: FC<Props> = ({ children }) => {
    return (
        <div className='Layout'>
            <main className='main'>
                <Header />
                <div className='main-home'>
                    {children}
                </div>
                <Footer />
            </main>
        </div>
    );
};

export { Layout };