import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './CarouselSwiper.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

const CarouselSwiper = ({ ArrayImages }) => {
    return (
        <>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {
                    ArrayImages.map((image) =>
                        <SwiperSlide>
                            <img src={image} alt='' />
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </>
    );
}
export { CarouselSwiper }