import React from 'react'
import '../Card/Card.scss'
 

interface Props {
    title:string
    image:string
    children: React.ReactNode
    classColorTitle: string
  }
  
  //componete
  const Card: React.FC<Props> = ({ title, children,  image, classColorTitle="classColorDefault" }) => {
    return (
        <div className='Cards' >
            <div className="card">
                <div className="card-body">
                    <h5 className={`card-title ${classColorTitle}`}>{title}</h5>
                    <p className="card-text">{children}</p>
                    <img src={image} className='search-img' alt=""/>
                </div>
            </div>
        </div>
    )
}   

export  {Card};