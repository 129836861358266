import React from 'react'
import './Home.scss'

import imgSoluciones from '../../Assets/Images/Home/Imagen_Soluciones.png'
import { Title } from '../../Components/Title/Title'
import { Subtitle } from '../../Components/Subtitle/Subtitle'
import { Button } from '../../Components/Button/Button'
import { CompService } from './CompService'
import { RouterLink } from '../../Components/RouterLink/RouterLink'
import { CarouselCom } from '../../Components/Carousel/Carousel'


import service_1 from '../../Assets/Images/Home/que_ofrece/Evangelizacion_3.png'
import service_2 from '../../Assets/Images/Home/que_ofrece/Servicios_de_automatizacion_3.png'
import service_3 from '../../Assets/Images/Home/que_ofrece/Toma_de_requerimientos_3.png'
import service_4 from '../../Assets/Images/Home/que_ofrece/Entrega_3.png'
import service_5 from '../../Assets/Images/Home/que_ofrece/Integracion_de_plataformas.png'
import service_6 from '../../Assets/Images/Home/que_ofrece/Adopcion_3.png'
import service_7 from '../../Assets/Images/Home/que_ofrece/Testing_3.png'
import serviceMovile from '../../Assets/Images/Home/Lista_completa.png'
import Enmascarar_left from '../../Assets/Images/Home/Enmascarar_left.png'
import Enmascarar_right from '../../Assets/Images/Home/Enmascarar_right.png'

import soluciones from '../../Assets/Images/Home/Soluciones.png'
import aliado from '../../Assets/Images/Home/Aliados.png'
import aliadoMovile from '../../Assets/Images/Home/Aliados_movile.png'
import gestion from '../../Assets/Images/Home/Gestion_proyectos.png'
import gestionMovile from '../../Assets/Images/Home/gestion_movil.png'

import caroucelAeronautica from '../../Assets/Images/Home/Aeronautica.png';
import caroucelConstruccion from '../../Assets/Images/Home/Construccion.jpg';
import caroucelEducacion from '../../Assets/Images/Home/Educacion.png';
import caroucelGobierno from '../../Assets/Images/Home/Gobierno.png';
import caroucelRetail from '../../Assets/Images/Home/Retail.jpg';
import caroucelSalud from '../../Assets/Images/Home/Salud.png';


import iso_completo from '../../Assets/Images/Home/iso_completo.png';

const Home = () => {

  const ArrayImages = [
    { title: 'Aeronáutica', image: caroucelAeronautica, description: 'Reúne todos los recursos técnicos y financieros para realizar el transporte aéreo.' },
    { title: 'Educación', image: caroucelEducacion, description: 'Operaciones para la gestión, analítica, investigación y nuevos sistemas de aprendizaje.' },
    { title: 'Construcción', image: caroucelConstruccion, description: '' },
    { title: 'Retail', image: caroucelRetail, description: '' },
    { title: 'Salud', image: caroucelSalud, description: '' },
    { title: 'Gobierno', image: caroucelGobierno, description: 'Desde seguridad en línea, ciberseguridad hasta respuesta a emergencias.' },
  ]

  const slidesArray = []
  if (ArrayImages.length > 0) {
    let contador = 1;
    for (let item of ArrayImages) {
      slidesArray.push(
        {
          key: contador,
          content: <CardImages image={item.image} description={item.description} title={item.title} />
        }
      )
      contador++
    }
  }

  return (
    <div className='Home'>
      <div className='row home-intro'>
        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 home-intro__content-img'>
          <img src={imgSoluciones} alt="" className='home-intro--img' />
        </div>
        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 home-intro-info'>
          <div className='row'>
            <div className='col-12 mt-5 d-movile home-intro--title'><Title><p><span className='color-movile'>Soluciones</span> empresariales en la nube</p></Title></div>
            <div className='col-12 mt-5 d-pc'><Title>Soluciones <br />empresariales</Title></div>
            <div className='col-12 mt-5 home-intro__p'><p className='home-intro__p--description'>Herramientas en la nube para la interacción entre tus colaboradores.</p></div>
            <div className='col-12 mt-5 home-intro__button'>
              <RouterLink to='/solutions' classLink="cont-link-img">
                <Button classButton='button__prymary'>VER SOLUCIONES</Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div className='home-offer row'>
        <div className='home-offer__img-back'></div>
        <div className='col-12'><Subtitle classSubTitle='subtitle__color--secundary text-center'>¿Qué ofrecemos?</Subtitle></div>
        <div className='home-offer__p col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl2 offset-xxl-2'>
          <p className='home--description'>
            Ofrecemos un amplio catálogo de aplicaciones tecnológicas en la nube que le ayudarán a incrementar la productividad de su negocio y a maximizar su retorno de inversión en tecnología.
          </p>
        </div>
        <div className='col-12 home-offer-element-content-center'>
          <div className='home-offer-content d-pc'>
            <div className='home-offer-fondo'>

            </div>
            <CompService image={service_1}>Evangelización y mentoring</CompService>
            <CompService image={service_2}>Servicios de automatización de procesos</CompService>
            <CompService image={service_3}>Toma de requerimientos</CompService>
            <CompService image={service_4}>Entrega de servicios administrados</CompService>
            <CompService image={service_5}>Integración de plataformas heterogéneas</CompService>
            <CompService image={service_6}>Adopción y capacitación a la usabilidad de la tecnología</CompService>
            <div className='home-offer__item--d-none'></div>
            <CompService image={service_7}>Testing QA y desarrollo de software</CompService>
          </div>
          <div className='home-offer-content--all d-movile'>
            <img src={serviceMovile} alt="" className='home-offer--img' />
          </div>
        </div>
      </div>

      <div className='home-trade row'>
        <div className='home-trade__img-back'></div>
        <div className='col-12'>
          <Subtitle classSubTitle='subtitle__color--secundary text-center'>
            Las principales industrias en las que incidimos:
          </Subtitle>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 home-trade__content'>
          <CarouselCom slidesArray={slidesArray} />
        </div>
      </div>

      <div className='home-solutions row'>
        <div className='home-solutions__img-back'></div>
        <div className='col-12'>
          <Subtitle classSubTitle='subtitle__color--secundary text-center'>
            <span className='color-b1'>Soluciones</span> para tus problemas de:
          </Subtitle>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 home-solutions__content-img d-pc'>
          <img src={soluciones} alt="" className='home-solutions--img' />
          <div className='home-solutions--row'>
            <div className='home-solutions__content-text home-solutions-start home--text-start'>
              <div className='home-solutions-item--1'>
                <div className='home--text'>Productividad en la nube</div>
              </div>
              <div className='home-solutions-item--2'>
                <div className='home--text'>Altos costos</div>
              </div>
              <div className='home-solutions-item--3'>
                <div className='home--text'>Omisión de sistemas</div>
              </div>
            </div>
            <div className='home-solutions__content-text home-solutions-end home--text-end'>
              <div className='home-solutions-item--4'>
                <div className='home--text'>Deficiencia en la gestión de proyectos</div>
              </div>
              <div className='home-solutions-item--5'>
                <div className='home--text'>Seguridad de la información</div>
              </div>
              <div className='home-solutions-item--6'>
                <div className='home--text'>Falta de herramientas</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 home-solutions__content-img d-movile'>
          <div className='row'>
            <div className='col-6 col-sm-8 col-md-8 home--text-movile-start'>
              <div className='home--text-movile home-text-1 '>
                <div className='home-text-span'>Productividad en la nube</div>
              </div>
              <div className='home--text-movile home-text-2 '>
                <div className='home-text-span'>Altos costos</div>
              </div>
              <div className='home--text-movile home-text-3 '>
                <div className='home-text-span'>Omisión de sistemas</div>
              </div>
            </div>
            <div className='col-6 col-sm-4 col-md-4 text-end'>
              <img src={Enmascarar_left} alt="" srcset="" className='home-solutions--img-movile' />
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-4 col-md-4'>
              <img src={Enmascarar_right} alt="" srcset="" className='home-solutions--img-movile' />
            </div>
            <div className='col-6 col-sm-8 col-md-8 home--text-movile-end'>
              <div className='home--text-movile home-text-4 '>
                <div className='home-text-span-end'>Deficiencia en la gestión de proyectos</div>
              </div>
              <div className='home--text-movile home-text-5 '>
                <div className='home-text-span-end'>Seguridad de la información</div>
              </div>
              <div className='home--text-movile home-text-6 '>
                <div className='home-text-span-end'>Falta de herramientas</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='home-ally row'>
        <div className='home-ally__img-back'></div>
        <div className='col-12'>
          <Subtitle classSubTitle='subtitle__color--secundary text-center'>
            Tu aliado en <span className='color-b1'>multinubes</span>
          </Subtitle>
          <p className='home--description'>Somos representantes de las principales nubes del mercado.</p>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 home-ally__content-img'>
          <img src={aliado} alt="" className='home-ally--img w-100 d-pc' />
          <img src={aliadoMovile} alt="" className='home-ally--img w-100 d-movile' />
        </div>
      </div>


      <div className='home-manage row'>
        <div className='home-manage__img-back'></div>
        <div className='col-12'>
          <Subtitle classSubTitle='subtitle__color--secundary text-center d-pc'>
            ¿Cómo <span className='color-b1'>gestionamos</span> los proyectos?
          </Subtitle>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 home-manage__content-img'>
          <img src={gestion} alt="" className='home-manage--img w-100 d-pc' />
          <img src={gestionMovile} alt="" className='home-manage--img w-100 d-movile' />
        </div>
      </div>

      <div className='home-iso row mt-5'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='home-iso__info'>
            <img src={iso_completo} alt="" srcset="" className='w-100' />
            <div className='home-iso__info-text'>
              <span className='home-iso__info--text'>Manejamos prácticas y certificaciones así como metodologías ágiles adaptables a tus necesidades.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Home };


const CardImages = ({ description, image, title }) => {
  return (
    <div className="CardImages">
      <div className="CardImages--img">
        <img src={image} alt={description} />
      </div>
      <div className="CardImages-content">
        <div className="CardImages-content__info">
          <div className="CardImages-content--back"></div>
          <div className="CardImages__p">
            <div className="CardImages__p-text">
              <p className="CardImages-p--text">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="CardImages-title">
        <p className="CardImages-title--p">{title}</p>
      </div>
    </div>
  );
};