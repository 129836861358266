import React, { useState } from "react";
import "./Contact.css";
import { Title } from "../../Components/Title/Title";
import contacto from "../../Assets/Images/Contact/Imagen.png";
import contactoMobile from "../../Assets/Images/Contact/Movil/Imagen.png";
import img_Background from "../../Assets/Images/Contact/Fondo.png";
import { Button } from "../../Components/Button/Button";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { Form } from "./Form";
import axios from "axios";
import { Modal } from "react-bootstrap";

const Contact = () => {
  axios.defaults.headers.common["Api_Key"] = "J7rTm2LpH9qA6wZ8xN3cE1bD4";
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    nombre: "",
    correo: "",
    pais: "México",
    mensaje: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

 
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const { Nombre, Correo, Pais, Mensaje } = formData;
    if (!Nombre || !Correo || !Mensaje) {
      alert('Por favor, complete todos los campos requeridos.');
      return;
    }
    axios
      .post(
        `https://chatbotiws.azurewebsites.net/api/Services/Send_correo_conective?Nombre=${Nombre}&Pais=${Pais}&Correo=${Correo}&Mensaje=${Mensaje}`
      )
      .then((res) => {
        console.log("Respuesta de la API:", res);
        setShowModal(true)
      })
      .catch((error) => {
        // console.error("Error");
        setShowModal(false);
      });
  };
  const isFormInvalid = !formData.Nombre || !formData.Correo || !formData.Mensaje;

  return (
    <div className="Contact">
      <div className="row contact-intro">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 contact-intro-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile contact-intro--title">
              <Title>
                <span className="color-movile">Contacto</span>
              </Title>
              <span className="color-movile-text">
                Si tienes alguna duda dejanos tus datos en el formulario y nos
                pondremos en contacto contigo a la brevedad.
              </span>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>Contacto</Title>
              <Subtitle>
                Si tienes alguna duda dejanos tus datos en el formulario y nos
                pondremos en contacto contigo a la brevedad.
              </Subtitle>
              <div className="contacto-oficinas">
                <Title>OFICINAS</Title>
                <Subtitle>
                  Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa,
                  Mexico City.
                </Subtitle>
                <Title>TELÉFONO</Title>
                <Subtitle>+52 55 8977 6528</Subtitle>
                <Title>CORREO ELECTRÓNICO</Title>
                <Subtitle>sales@connectiveit.com.mx</Subtitle>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 contact-intro__content-img">
          <img src={contacto} alt="" className="contact-intro--img" />
          <img
            src={contactoMobile}
            alt=""
            className="contact-intro--img--mobile"
          />
        </div>
      </div>

      <div className="col-12 mt-5 d-pc-mobile">
        <div className="contacto-oficinas">
          <Title>OFICINAS</Title>
          <Subtitle>
            Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa, Mexico
            City.
          </Subtitle>
          <Title>TELÉFONO</Title>
          <Subtitle>+52 55 8977 6528</Subtitle>
          <Title>CORREO ELECTRÓNICO</Title>
          <Subtitle>helpdesk@redinet.us</Subtitle>
        </div>
      </div>

      <div className="contacto-card">
        <div className="form-contacto">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="custom-color">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group-up">
                      <div className="name">
                        <label htmlFor="nombre">NOMBRE</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Nombre"
                          value={formData.Nombre}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="correo">
                        <label htmlFor="correo">CORREO ELECTRÓNICO</label>
                        <input
                          type="email"
                          className="form-control"
                          id="Correo"
                          value={formData.Correo}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="selector_country">
                      <h1>PAÍS</h1>
                      <select
                        id="Pais"
                        className="form-control"
                        data-role="dropdownlist"
                        value={formData.Pais}
                        onChange={handleChange}
                      >
                        <option value="México">México</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Perú">Perú</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="USA">USA</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Chile">Chile</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Panamá">Panamá</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Brasil">Brasil</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mensaje">MENSAJE</label>
                      <textarea
                        className="form-control"
                        id="Mensaje"
                        rows="5"
                        value={formData.Mensaje}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="form-enviar">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="noSoyRobot"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="noSoyRobot"
                        >
                          No soy un robot
                        </label>
                      </div>
                      <div className="form-button-enviar">
                        <button  variant="primary" type="submit" className="button__prymary">
                          ENVIAR
                        </button>
                      </div>
                    </div>
                  </form>
                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>¡Correo enviado exitosamente!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      El correo se ha enviado correctamente.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowModal(false)}
                        disabled={isFormInvalid}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Contact };
