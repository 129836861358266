import React from 'react';
import './ContentItemCaroucel.scss'
import { Subtitle } from '../../Components/Subtitle/Subtitle';


interface Props {
    img: string
    title: string
    children: React.ReactNode
    showEnvolvent: boolean
    imgEnvolvent: string
    class_bg: string
    RectánguloSeccion: string
}

const ContentItemCaroucel: React.FC<Props> = ({ img, title, children, showEnvolvent = false, imgEnvolvent, class_bg = 'bg-color-caroucel', RectánguloSeccion }) => {
    return (
        <div className='ContentItemCaroucel content-caroucel-item'>
            <div className={`content-caroucel__item ${class_bg}`}>
                <div className='content-caroucel__item-img-s'>
                    <img src={img} alt="" srcSet="" className='w-100' />
                    {showEnvolvent &&
                        <div className='content-caroucel_img-envolent'>
                            <img src={imgEnvolvent} alt="" srcSet="" className='w-100' />
                        </div>
                    }
                </div>
                <div className='content-caroucel__item-img-e'>
                    <img src={RectánguloSeccion} alt="" srcSet="" className='w-100' />
                    <div className='content-caroucel__item-info-col'>
                        <div className='content-caroucel__item-info'>
                            <Subtitle>{title}</Subtitle>
                            <div className='content-caroucel__item-info-p'>
                                <p className='content-caroucel__item-info--text'>
                                    {children}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export { ContentItemCaroucel }; 