import React from 'react';
import './CarouselBoostrap.scss'

interface Props {
    children: React.ReactNode
    indicators: number
}

const CarouselBoostrap: React.FC<Props> = ({ children, indicators = 1 }) => {

    let contenIndicador = []
    for (let i = 0; i < indicators; i++) {
        if (i === 0) {
            contenIndicador.push(<button key={i} type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>)
        } else {
            contenIndicador.push(<button key={i} type="button" data-bs-target="#carouselIndicators" data-bs-slide-to={`${i}`} aria-label={`Slide ${i + 1}`}></button>)
        }
    }

    return (
        <div id="carouselIndicators" className="CarouselBoostrap carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {
                    contenIndicador
                }
            </div>
            <div className="carousel-inner">
                {
                    children
                }
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export { CarouselBoostrap };