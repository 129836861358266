import React from 'react'
import './Header.css'

import Logo from '../../Assets/Images/Menu/logo escritorio.png'
import Phone from '../../Assets/Images/Menu/Movil/Telefono.png'
import { RouterLink } from '../../Components/RouterLink/RouterLink'
import { Button } from '../../Components/Button/Button'



const Header = () => {
  return (
    <div className='Header'>
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <RouterLink to='/' classLink="cont-link-img">
            <img src={Logo} alt='' />
          </RouterLink>
          <div className='nav-content__buttons'>
            <button className="navbar-toggler head-nav-button head-nav__button--menu button-menu-svg" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              {/* <span className="navbar-toggler-icon"></span> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
              </svg>
            </button>
            <button className="navbar-toggler head-nav-button head-nav__button--call" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src={Phone} alt='' />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 nav-link-text">
              <li className="nav-item nav-link">
                <RouterLink to='/about' classLink='dropdown-item'>Nosotros</RouterLink>
              </li>
              {/* <li className="nav-item nav-link">
                <RouterLink to='/solutions' classLink='dropdown-item'>Soluciones</RouterLink>
              </li> */}
              <li className="nav-item dropdown header-nav--items">
                <samp className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Soluciones
                </samp>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><RouterLink to='/solutions' classLink='dropdown-item'>Portal Web</RouterLink></li>
                  <li> </li>
                  <li><RouterLink to='/dynamics' classLink='dropdown-item'>Dynamics</RouterLink></li>
                  <li><RouterLink to='/powerapps' classLink='dropdown-item'>Power Apps</RouterLink></li>
                  <li><RouterLink to='/teamviewer' classLink='dropdown-item'>Team Viewer</RouterLink></li>
                </ul>
              </li>

              <li className="nav-item nav-link">
                <RouterLink to='/resources' classLink='dropdown-item'>Recursos</RouterLink>
              </li>
              {/* <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Nosotros
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><RouterLink to='/about' classLink='dropdown-item'>Nosotros</RouterLink></li>
                  <li><hr className="dropdown-divider" /> </li>
                  <li><RouterLink to='/about' classLink='dropdown-item'>Nosotros</RouterLink></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <samp className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Soluciones
                </samp>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><RouterLink to='/solutions' classLink='dropdown-item'>Soluciones</RouterLink></li>
                  <li><hr className="dropdown-divider" /> </li>
                  <li><RouterLink to='/solutions' classLink='dropdown-item'>Soluciones</RouterLink></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Recurosos
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><RouterLink to='/resources' classLink='dropdown-item'>Recurosos</RouterLink></li>
                  <li><hr className="dropdown-divider" /> </li>
                  <li><RouterLink to='/resources' classLink='dropdown-item'>Recurosos</RouterLink></li>
                </ul>
              </li> */}
            </ul>
            <form className="d-flex">
              <RouterLink to='/contact' classLink='dropdown-item'>
                <Button classButton='contacto'> Contacto </Button>
              </RouterLink>
            </form>
          </div>
        </div>
      </nav>
    </div>
  )
}

export { Header };