import React from 'react';

interface Props {
    image: string
    children: React.ReactNode
}
const CompService: React.FC<Props> = ({ image, children }) => {
    return (
        <div className='home-offer__service'>
            <img src={image} alt="" />
            <div className='home-offer__service-p'>
                <p className='home-offer__service--text'>{children}</p>
            </div>
        </div>
    );
};

export { CompService };