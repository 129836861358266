import React from "react";
import './Carousel.scss'

import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";

const CarouselCom = ({ slidesArray, showNavigation = true, width = '70%', height = '450px' }) => {

    return (
        <div className="content-Carousel" style={{ width: width, height: height, margin: "0 auto" }}>
            <Carousel
                slides={slidesArray}
                goToSlide={0}
                offsetRadius={3}
                animationConfig={config.gentle}
                showNavigation={showNavigation}
                goToSlideDelay={1}
            />
        </div>
    );
};
export { CarouselCom };

